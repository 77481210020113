

.pl__ring {
    animation: rotate 2s linear infinite;
    stroke-dasharray: 0, 515, 0, 0;
  }
  
  @keyframes rotate {
    0% {
      stroke-dasharray: 0, 0, 0, 0, 257, 0, 258, 0;
    }
    50% {
      stroke-dasharray: 0, 0, 0, 0, 0, 515, 0, 0;
    }
    100% {
      stroke-dasharray: 0, 257, 0, 0, 1, 0, 0, 258;
    }
  }
  body{
    margin: 0px;
  }